<template>
  <div id="inside" style="">
    <div class="container">
      <div class="row">
        <div class="">
        </div>
        <div class="col-md-9">
          <div class="card">
            <div class="card-header text-light bg-blue" style=""><i class="fa fa-folder-open-o fa-fw mr-2"></i> Contratos Negociaveis</div>
            <div class="row" v-if="loadDividas">
                  <div class="justify-center" style="margin-top:5px;margin-left:0px;width:100% !important;padding:20px">
                      <ProgressLoading v-bind:loading="loadDividas"/>
                  </div>
              </div>

            <div class="card-body" style="" v-if="!loadDividas && dividas.length == 0">
              <div class="row">
                <div class="col-md-12 col-12">
                  <h5 class=""></h5>
                </div>
              </div>
              <div class="row">
                  <div class="justify-center" style="margin-top:5px;margin-left:0px;width:100% !important;">
                      <p class="text-info mb-0">Você não possui acordos negociáveis</p>
                  </div>
              </div>
            </div>

            <div class="card-body" style="" v-for="divida in dividas" :key="divida.AccountNumber">
              <div class="row">
                <div class="col-md-12 col-12">
                  <h5 class="">{{ divida.AccountNotes }}</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 col-12">
                  <p class="m-0 text-info">Valor:</p>
                  <h5>R$ {{ formatValue(divida.value) }}</h5>
                </div>
                <div class="col-12 col-md-8">
                  <p class="m-0">Contrato: {{ divida.AccountNumber }}</p>
                  <p class="text-info">Data: {{ formatData(divida.dueDate, "YYYY-MM-DD", "L") }}</p>
                  <h6 class="">Detalhes:</h6>
                  <p>{{ divida.AccountNotes }}</p>
                  <router-link :to="{ name: 'negociarDivida', params: {
                    contractInfo: divida}}"> <a class="btn btn-secondary mr-1 mb-1" >Negociar</a></router-link>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <div class="col-12 col-md-3" style="">
          <ContentMenu></ContentMenu>
        </div>
      </div>
      <div class="row" style="">
        <div class="col-md-12 col-15 my-3" style="">
          <hr> <a class="btn btn-link mx-auto mb-2" @click="voltar"><i class="fa fa-chevron-left fa-fw"></i>Voltar</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { formatCurrencieValueBRL as formatValue, formatData } from "@/utils/helpers";
import ContentMenu from '../components/ContentMenu.vue';
import ProgressLoading from '../../../modules/auth/components/ProgressLoading.vue';
export default {
    name: "MinhasDividas",
    components: {
      ContentMenu,
      ProgressLoading
    },
    data () {
        return {
            loadDividas: false,
            dividas: null
        }
    },
    async created() {
        if (this.dividas === null ) {
            this.loadDividas = true;
            const response = await this.ActionFindDividas();
            this.dividas = response.data.results;
            this.loadDividas = false;
        }
    },
    methods: {
        formatValue,
        formatData,
        ...mapActions('minhasDividas', ['ActionFindDividas']),
        voltar() {
             this.$router.push({ name: 'dashboard' });
        },
    },
};
</script>