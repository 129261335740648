<template>
  <div id="inside">
    <div class="container">
      <div class="row">
        <div
          class="
            d-flex
            align-items-start
            justify-content-start
            flex-column
            px-2
            pb-4
            col-lg-12
          "
        >
          <div class="btn-group btn-group-sm">
            <a
              href="/"
              class="btn btn-link d-flex flex-column justify-content-center"
              ><i class="fa fa-home fa-fw fa-lg"></i
            ></a>
            <router-link
              v-for="link in links"
              :key="link.text"
              :to="{
                name: link.route,
              }"
              ><a class="btn btn-link">{{ link.text }}</a></router-link
            >
          </div>
        </div>
        <div class="col-lg-12">
          <h1>Boletos ativos</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-3"></div>
        <div class="d-inline-flex flex-wrap flex-column col-acao col-lg-6">
          <div class="card" style="" v-if="loadBoletos">
            <div class="card-header">
              <i class="fa fa-fw mr-2 fa-circle text-warning"></i>&nbsp;Boletos
              ativos
            </div>
            <div class="row">
              <div
                class="justify-center"
                style="
                  margin-top: 5px;
                  margin-left: 0px;
                  width: 100% !important;
                "
              >
                <ProgressLoading v-bind:loading="loadBoletos" />
              </div>
            </div>
          </div>

          <div
            class="card"
            style=""
            v-if="!loadBoletos && Object.keys(boletos).length == 0"
          >
            <div class="card-header">
              <i class="fa fa-fw mr-2 fa-circle text-warning"></i>&nbsp;Boletos
              ativos
            </div>
            <div class="row">
              <div class="justify-center text-center-msg">
                <p class="text-info mb-0">Você não possui boletos ativos</p>
              </div>
            </div>
          </div>
          <div v-if="!loadBoletos">
            <div
              class="card"
              v-for="(value, key, index) in boletos"
              :key="`${key}-${index}`"
            >
              <div class="card-header">
                <i class="fa fa-fw mr-2 fa-circle text-warning"></i
                >&nbsp;Boletos ativos
              </div>
              <div>
                <div class="card-body">
                  <!-- <div class="row">
                      <div class="col-md-12 col-12">
                        <h6 class="">Origem do boleto</h6>
                          <p>Número de Parcelas</p>
                      </div>
                    </div>-->
                  <div class="row">
                    <div class="col-md-12">
                      <div
                        class="row"
                        v-for="(deal, k, i) in value"
                        :key="`${k}-${i}`"
                      >
                        <!--<div class="col-md-6 col-6">
                            <p class="m-0 text-info">Valor:</p>
                            <h6>R$ {{ formatValue(deal.value) }}</h6>
                          </div>
                          <div class="col-md-6 col-6">
                            <p class="m-0 text-info">Vencimento:</p>
                          <h6>{{ formatData(deal.dueDate, "YYYY-MM-DD", "L") }}</h6>
                          </div>-->

                        <div
                          class="col-md-12 col-12"
                          v-for="(bill, k2, i2) in deal"
                          :key="`${k2}-${i2}`"
                        >
                          <div v-if="i == 0">
                            <p class="text-info mb-0">Tipo:</p>
                            <p class="mb-1">{{ k2 }}</p>
                            <p class="text-info mb-0">Parcelas:</p>
                            <p class="mb-1">
                              {{
                                getBillValue(bill["deals"], k2)["installments"]
                              }}
                            </p>
                            <p class="text-info mb-0">Valor:</p>
                            <p class="mb-1">
                              R$
                              {{
                                formatValue(
                                  getBillValue(bill["deals"], k2)["value"]
                                )
                              }}
                            </p>
                            <p class="text-info mb-0">Vencimento:</p>
                            <p class="mb-1">
                              {{
                                formatData(
                                  getBillValue(bill["deals"], k2)["dueDate"],
                                  "YYYY-MM-DD",
                                  "L"
                                )
                              }}
                            </p>
                          </div>
                          <p class="text-info mb-0">Contrato:</p>
                          <p class="mb-1">{{ k }}</p>
                          <p class="text-info mb-0" style="">Decrição:</p>
                          <p contenteditable="true">{{ bill.accountNotes }}</p>
                        </div>
                      </div>

                      <p class="text-info mb-1">Linha digitável:</p>
                      <p class="codebar" @click="copiarLinhaDigitavel(key)">
                        {{ key }}
                      </p>
                      <a
                        class="btn mr-1 mb-1 btn-outline-primary btn-block"
                        @click="copiarLinhaDigitavel(key)"
                        href="#"
                        >Copiar linha digitável</a
                      >
                      <!-- <a class="btn mr-1 mb-1 btn-outline-primary btn-block" href="#"><i class="fa fa-download fa-fw"></i> Baixar boleto</a> -->
                      <a
                        class="btn mr-1 mb-1 btn-outline-primary btn-block"
                        @click="sendSms(key)"
                        href="#"
                        ><i class="fa fa-fw fa-mobile"></i>&nbsp;Receber por
                        SMS</a
                      >
                      <a
                        class="btn mr-1 mb-1 btn-outline-primary btn-block"
                        @click="sendEmail(key)"
                        href="#"
                        ><i class="fa fa-fw fa-envelope-o"></i>&nbsp;Receber por
                        e-mail</a
                      >
                      <button
                        type="button"
                        class="btn mr-1 mb-1 btn-outline-primary btn-block"
                        style="color: ##fff"
                        @click="enviarComprovante(key)"
                      >
                        <i class="fa fa-upload fa-fw"></i> Enviar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-1"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  formatCurrencieValueBRL as formatValue,
  formatData,
} from "@/utils/helpers";
//import ContentMenu from '../components/ContentMenu.vue';
import ProgressLoading from "../../../modules/auth/components/ProgressLoading.vue";
export default {
  name: "MeusBoletos",
  components: {
    // ContentMenu,
    ProgressLoading,
  },
  data() {
    return {
      links: [],
      loadBoletos: false,
      boletos: null,
    };
  },

  async created() {
    this.user.Menus.forEach((menuId) => {
      switch (parseInt(menuId)) {
        case 2:
          this.links.push({
            icon: "",
            text: "Contratos Negociáveis",
            route: "/dashboard/minhas-dividas",
          });
          break;
        case 1:
          this.links.push({
            icon: "",
            text: "Boletos Ativos",
            route: "meusBoletos",
          });
          break;
        case 3:
          this.links.push({
            icon: "",
            text: "Acordos",
            route: "/dashboard/meus-acordos",
          });
          break;
      }
    });

    if (this.boletos === null) {
      this.loadBoletos = true;
      const responseBoleto = await this.ActionFindBoletoExpress();
      var todosBoletos = responseBoleto.data.results;
      var dict = {};

      for (var n = 0; n < todosBoletos.length; n++) {
        for (var l = 0; l < todosBoletos[n]["deals"].length; l++) {
          if (dict[todosBoletos[n]["deals"][l]["linhaDigitavel"]] == null)
            dict[todosBoletos[n]["deals"][l]["linhaDigitavel"]] = {};

          if (
            dict[todosBoletos[n]["deals"][l]["linhaDigitavel"]][
              todosBoletos[n]["contractNumber"]
            ] == null
          )
            dict[todosBoletos[n]["deals"][l]["linhaDigitavel"]][
              todosBoletos[n]["contractNumber"]
            ] = {};

          if (
            dict[todosBoletos[n]["deals"][l]["linhaDigitavel"]][
              todosBoletos[n]["contractNumber"]
            ][todosBoletos[n]["deals"][l]["type"]] == null
          ) {
            var nObj = todosBoletos[n];
            nObj["deal"] = todosBoletos[n]["deals"][l];
            dict[todosBoletos[n]["deals"][l]["linhaDigitavel"]][
              todosBoletos[n]["contractNumber"]
            ][todosBoletos[n]["deals"][l]["type"]] = nObj;
          }
        }
      }

      this.boletos = dict;
      this.loadBoletos = false;
    }
  },
  computed: {
    ...mapState("auth", ["user"]),
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
  },
  methods: {
    formatValue,
    formatData,
    ...mapActions("meusBoletos", [
      "ActionFindBoletoExpress",
      "ActionSendEmailOrSmsText",
      "ActionSendAccessLog",
    ]),
    voltar() {
      this.$router.push({ name: "dashboard" });
    },
    async enviarComprovante(linhaDigitavel) {
      this.$router.push({
        name: "comprovante",
        params: { linhadigitavel: linhaDigitavel },
      });
    },
    getBillValue(offers, type) {
      var response = {};

      for (var n = 0; n < offers.length; n++) {
        if (offers[n]["type"] == type) response = offers[n];
      }
      return response;
    },
    async sendSms(linhaDigitavel) {
      if (this.user.MobileNumber == "" || this.user.MobileNumber == null) {
        this.flashMessage.show({
          timeout: 1000,
          blockClass: "custom-block-class",
          status: "error",
          title: "Erro",
          message:
            "Você não possui um número de celular cadastrado no sistema, por favor atualize seus dados",
        });
        this.goToUpdateInfo();
      } else {
        this.loadBoletos = true;

        let response = await this.ActionSendEmailOrSmsText({
          LinhaDigitavel: linhaDigitavel,
          Email: "",
          PhoneNumber: this.user.PhoneNumber,
        });
        this.loadBoletos = false;

        if (!response.data.error) {
          this.flashMessage.show({
            timeout: 1000,
            status: "success",
            title: "Sucesso",
            blockClass: "custom-block-class",
            message: "Linha digitável enviado por sms com sucesso",
          });
        } else {
          this.flashMessage.show({
            timeout: 1000,
            blockClass: "custom-block-class",
            status: "error",
            title: "Erro",
            message:
              "Ocorreu um erro ao tentar enviar o SMS, tente novamente mais tarde",
          });
        }
      }
    },
    async sendEmail(linhaDigitavel) {
      if (this.user.Email == "" || this.user.Email == null) {
        this.flashMessage.show({
          timeout: 1000,
          status: "error",
          title: "Erro",
          blockClass: "custom-block-class",
          message:
            "Você não possui um e-mail cadastrado no sistema, por favor atualize seus dados",
        });
        this.goToUpdateInfo();
      } else {
        this.loadBoletos = true;

        let response = await this.ActionSendEmailOrSmsText({
          LinhaDigitavel: linhaDigitavel,
          Email: this.user.Email,
          PhoneNumber: "",
        });

        this.loadBoletos = false;
        if (!response.data.error) {
          this.flashMessage.show({
            timeout: 1000,
            blockClass: "custom-block-class",
            status: "success",
            title: "Sucesso",
            message: "Linha digitável enviado por e-mail com sucesso",
          });
        } else {
          this.flashMessage.show({
            timeout: 1000,
            blockClass: "custom-block-class",
            status: "error",
            title: "Erro",
            message:
              "Ocorreu um erro ao tentar enviar o e-mail, tente novamente mais tarde",
          });
        }
      }
    },
    async billDownload(deal) {
      console.log(deal);
      this.loadBoletos = true;

      await this.ActionSendAccessLog({
        ValueId: 12,
      });

      this.loadBoletos = false;
    },
    async copiarLinhaDigitavel(linhaDigitavel) {
      const elem = document.createElement("textarea");
      elem.value = linhaDigitavel;
      document.body.appendChild(elem);
      elem.select();
      document.execCommand("copy");
      document.body.removeChild(elem);

      this.loadBoletos = true;

      await this.ActionSendAccessLog({
        ValueId: 11,
        LinhaDigitavel: linhaDigitavel,
      });

      this.loadBoletos = false;

      this.flashMessage.show({
        timeout: 1000,
        status: "success",
        title: "Sucesso",
        position: "top right",
        blockClass: "custom-block-class",
        message: "Linha digitável foi copiada para o seu clipboard",
      });
    },
  },
};
</script>