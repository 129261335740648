<template>
  <div id="inside">
    <div class="container">
      <div class="row">
        <div
          class="
            d-flex
            align-items-start
            justify-content-start
            flex-column
            px-2
            pb-4
            col-lg-12
          "
        >
          <div class="btn-group btn-group-sm">
            <a
              href="/"
              class="btn btn-link d-flex flex-column justify-content-center"
              ><i class="fa fa-home fa-fw fa-lg"></i
            ></a>
            <router-link
              v-for="link in links"
              :key="link.text"
              :to="{
                name: link.route,
              }"
              ><a class="btn btn-link">{{ link.text }}</a></router-link
            >
          </div>
        </div>
        <div class="col-lg-12">
          <h1>Comprovante</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-3"></div>
        <div class="d-inline-flex flex-wrap flex-column col-acao col-lg-6">
          <div class="card">
            <div class="card-header">
              <i class="fa fa-fw mr-2 fa-circle text-warning"></i>&nbsp;Boleto
              ativo
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 col-12">
                  <h6 class="">Comprovante de pagamento</h6>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <p class="text-info mb-0">Referência:</p>
                  <p class="mb-1">{{ linhadigitavel }}</p>
                  <p class="mt-3 mb-4">
                    Enviar comprovante de pagamento (formatos: jpg, jpeg, png,
                    gif, pdf)
                  </p>
                  <form v-on:submit="enviarComprovante" v-on:submit.prevent>
                    <div class="row">
                      <div class="col-md-5 mb-4" style="">
                        <label for="inputState" class="small"
                          >Tipo de pagamento:</label
                        >
                        <select
                          class="form-control"
                          v-model="selectedTypeProofPayment"
                        >
                          <option
                            v-for="(option, index) in typesProofPayment"
                            :key="index"
                            required
                          >
                            {{ option.Type }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-7 mb-4" style="">
                        <div class="form-group">
                          <label class="small">Comprovante:</label>
                          <div class="custom-file">

                            <input type="file" name="file" ref="file" />

                            <div class="invalid-feedback">
                              Erro. Formato inválido.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      class="btn mr-1 mb-1 btn-outline-primary btn-block"
                      style="color: ##fff"
                      href="#"
                    >
                      <i class="fa fa-upload fa-fw"></i> Enviar Comprovante
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-1"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { mapActions, mapState } from "vuex";
import {
  formatCurrencieValueBRL as formatValue,
  formatData,
} from "@/utils/helpers";
//import ContentMenu from '../components/ContentMenu.vue';
export default {
  mixins: [validationMixin],
  name: "Comprovante",
  components: {},
  validations: {
    form: {
      file: { required },
      Reference: { required },
      OwnerID: { required },
      TypeProofPaymentID: { required },
      selectedTypeProofPayment: { required },
    },
  },
  data() {
    return {
      links: [],
      linhadigitavel: "",
      file: null,
      selectedTypeProofPayment: null,
      typesProofPayment: [],
      debtorProofPaymentId: null,
    };
  },
  async created() {
    this.user.Menus.forEach((menuId) => {
      switch (parseInt(menuId)) {
        case 2:
          this.links.push({
            icon: "",
            text: "Contratos Negociáveis",
            route: "/dashboard/minhas-dividas",
          });
          break;
        case 1:
          this.links.push({
            icon: "",
            text: "Boletos Ativos",
            route: "meusBoletos",
          });
          this.links.push({
            icon: "",
            text: "Comprovante",
            route: "comprovante",
            params: { linhadigitavel: this.linhadigitavel },
          });
          break;
        case 3:
          this.links.push({
            icon: "",
            text: "Acordos",
            route: "/dashboard/meus-acordos",
          });
          break;
      }
    });
    this.linhadigitavel = this.$route.params.linhadigitavel;
    this.debtorProofPaymentId = this.$route.params.debtorProofPaymentID;

    this.buscarTiposPagamento();
  },

  computed: {
    ...mapState("auth", ["user"]),
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
  },
  methods: {
    formatValue,
    formatData,
    ...mapActions("meusBoletos", [
      "ActionFindBoletoExpress",
      "ActionSendEmailOrSmsText",
      "ActionSendAccessLog",
    ]),
    ...mapActions("comprovante", [
      "ActionUploadProofPayment",
      "ActionListTypeProofPayment",
    ]),
    voltar() {
      this.$router.push({ name: "dashboard" });
    },

    async enviarComprovante(linhaDigitavel) {
      let dataForm = new FormData();

      for (let file of this.$refs.file.files) {
        dataForm.append(`File`, file);
      }

      if (this.selectedTypeProofPayment == "Boleto") {
        this.selectedTypeProofPayment = 2;
      } else {
        this.selectedTypeProofPayment = 1;
      }

      dataForm.append("Reference", this.linhadigitavel);
      dataForm.append("OwnerID", 2);
      dataForm.append("TypeProofPaymentID", this.selectedTypeProofPayment);
      if(this.debtorProofPaymentId) {
        dataForm.append("DebtorProofPaymentID", this.debtorProofPaymentId);
      } else {
        dataForm.append("DebtorProofPaymentID", 0);
      }
      try {
        var resp = await this.ActionUploadProofPayment(dataForm);

        if (!resp.data.error) {
          this.flashMessage.show({
            timeout: 1000,
            status: "success",
            title: "Sucesso",
            message: "Comprovante enviado com sucesso",
          });

          this.$router.push({
            name: "dashboard",
            params: { linhadigitavel: linhaDigitavel },
          });
        } else {
          this.flashMessage.show({
            timeout: 1000,
            status: "error",
            title: "ocorreu um erro",
            message: resp.data.message,
          });
        }
      } catch (error) {
        this.flashMessage.show({
          timeout: 1000,
          status: "error",
          title: "Ocorreu um erro",
          message: error.data.message,
        });
        this.loading = false;
      }
    },

    async buscarTiposPagamento() {
      var resp = await this.ActionListTypeProofPayment();
      this.typesProofPayment = resp;
      this.selectedTypeProofPayment = resp[0];
    },
  },
};
</script>